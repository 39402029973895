var _defineProperty = require("/tmp/build_0100fca2/services/app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var merge = require("lodash.merge");

var _require = require("../data/common"),
    ensureGeolocation = _require.ensureGeolocation;

var constants = require("./constants");
/**
 * Creates a shell object of a minimal listing and merges that with the input. This ensures that the necessary listing
 * structure is defined.
 *
 * @param listing
 */


var ensureListing = function ensureListing(listing) {
  var empty = {
    id: null,
    type: "listing",
    attributes: {
      publicData: {
        geolocation: ensureGeolocation({}),
        eventRoles: [],
        following: [],
        interests: [],
        isNPOListing: false,
        isVolunteerListing: false,
        methodPriceChoices: {
          chat: "choice_100",
          inPerson: "choice_100"
        },
        reservableSlots: [],
        supportedNPOs: []
      },
      metadata: {
        stats: {
          actualImpact: {},
          completedMeetings: 0,
          nonprofitsImpacted: []
        }
      }
    },
    images: []
  };
  return merge(empty, listing);
};
/**
 * Creates a shell object of a minimal own listing and merges that with the input. This ensures that the necessary
 * listing structure is defined.
 *
 * @param listing
 */


var ensureOwnListing = function ensureOwnListing(listing) {
  var empty = {
    id: null,
    type: "ownListing",
    attributes: {
      publicData: {
        geolocation: {},
        methodPriceChoices: {
          chat: "choice_100",
          inPerson: "choice_100"
        },
        reservableSlots: [],
        supportedNPOs: []
      },
      privateData: {
        following: []
      },
      metadata: {
        stats: {
          nonprofitsImpacted: []
        }
      }
    },
    images: []
  };
  return merge(empty, listing);
};
/**
 * Ensures the listing structure of a nonprofit listing
 *
 * @param   {Object}    listing
 * @return  {Object}
 */


var ensureNonprofitListing = function ensureNonprofitListing(listing) {
  var empty = {
    attributes: {
      publicData: {
        impact: {
          description: "",
          donationSum: 10000,
          impactUnits: 0,
          impactType: {
            singular: "",
            plural: ""
          },
          pictureId: null
        }
      }
    }
  };
  return merge(ensureListing(empty), listing);
};
/**
 * Ensures the nonprofit listing owned by the user (intended for future use)
 *
 * @param   {Object}    listing
 * @return  {Object}
 */


var ensureOwnNonprofitListing = function ensureOwnNonprofitListing(listing) {
  // Currently alias of ensureNonprofitListing()
  return ensureNonprofitListing(listing);
};
/**
 * Maps included relations to the listing object based on the SDK response. This must be the entire SDK response as
 * provided by the Sharetribe SDK.
 *
 * @param sdkResponse
 * @returns sdkResponse
 */


var mapRelationsFromSdkResponse = function mapRelationsFromSdkResponse(sdkResponse) {
  if (!Array.isArray(sdkResponse.data.data)) {
    sdkResponse.data.data = [sdkResponse.data.data];
  }

  sdkResponse.data.data.map(function (listing) {
    // Map author
    listing.author = sdkResponse.data.included.find(function (entity) {
      return entity.id.uuid === listing.relationships.author.data.id.uuid && entity.type === "user";
    }); // Map author profile image

    if (listing.author && listing.author.relationships && listing.author.relationships.profileImage) {
      listing.author.profileImage = sdkResponse.data.included.find(function (entity) {
        return listing.author.relationships.profileImage.data && entity.id.uuid === listing.author.relationships.profileImage.data.id.uuid && entity.type === "image";
      });
    }

    return listing;
  });
  return sdkResponse;
};

module.exports = _objectSpread({}, constants, {
  ensureListing: ensureListing,
  ensureNonprofitListing: ensureNonprofitListing,
  ensureOwnListing: ensureOwnListing,
  ensureOwnNonprofitListing: ensureOwnNonprofitListing,
  mapRelationsFromSdkResponse: mapRelationsFromSdkResponse
});