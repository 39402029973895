var _defineProperty = require("/tmp/build_0100fca2/services/app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var merge = require("lodash.merge");

var config = require("@givsly/config");

var sharetribeSdk = require("sharetribe-flex-sdk");

var _sharetribeSdk$types = sharetribeSdk.types,
    BigDecimal = _sharetribeSdk$types.BigDecimal,
    Money = _sharetribeSdk$types.Money;

var constants = require("./constants");

var _require = require("../data"),
    user = _require.user;

var LINE_ITEM_DONATION = constants.LINE_ITEM_DONATION,
    LINE_ITEM_OPERATOR_FEE = constants.LINE_ITEM_OPERATOR_FEE,
    LINE_ITEM_GIVSLY_CREDIT = constants.LINE_ITEM_GIVSLY_CREDIT,
    LINE_ITEM_PAYMENT_FEE = constants.LINE_ITEM_PAYMENT_FEE,
    PROCESS_NAME_BOOKING = constants.PROCESS_NAME_BOOKING,
    PROCESS_NAME_PAYMENT = constants.PROCESS_NAME_PAYMENT,
    TRANSITION_CANCEL = constants.TRANSITION_CANCEL,
    TRANSITION_CANCEL_AFTER_COMPLETION = constants.TRANSITION_CANCEL_AFTER_COMPLETION,
    TRANSITION_CANCEL_AFTER_DELIVERY = constants.TRANSITION_CANCEL_AFTER_DELIVERY,
    TRANSITION_CLOSE = constants.TRANSITION_CLOSE,
    TRANSITION_COMPLETE = constants.TRANSITION_COMPLETE,
    TRANSITION_CUSTOMER_CANCEL = constants.TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_DECLINE = constants.TRANSITION_DECLINE,
    TRANSITION_DELIVER = constants.TRANSITION_DELIVER,
    TRANSITION_EXPIRE = constants.TRANSITION_EXPIRE,
    TRANSITION_PROVIDER_CANCEL = constants.TRANSITION_PROVIDER_CANCEL,
    TRANSITION_CANCEL_AFTER_CAPTURE = constants.TRANSITION_CANCEL_AFTER_CAPTURE,
    TRANSITION_CANCEL_BEFORE_CAPTURE = constants.TRANSITION_CANCEL_BEFORE_CAPTURE,
    TRANSITION_EXPIRE_PAYMENT = constants.TRANSITION_EXPIRE_PAYMENT;
/**
 * Maps included relations to the notification object based on the SDK response.
 * This must be the entire SDK response as provided by the Sharetribe SDK.
 *
 * @param sdkResponse
 * @returns sdkResponse
 */

var mapRelationsFromSdkResponse = function mapRelationsFromSdkResponse(sdkResponse) {
  if (!Array.isArray(sdkResponse.data.data)) {
    sdkResponse.data.data = [sdkResponse.data.data];
  }

  sdkResponse.data.data.map(function (transaction) {
    // Map provider & provider profile image
    if (transaction.relationships.provider && transaction.relationships.provider.data) {
      // Provider
      transaction.provider = sdkResponse.data.included.find(function (entity) {
        return entity.id.uuid === transaction.relationships.provider.data.id.uuid && entity.type === "user";
      }); // Provider profile image

      if (transaction.provider && transaction.provider.relationships && transaction.provider.relationships.profileImage) {
        transaction.provider.profileImage = sdkResponse.data.included.find(function (entity) {
          return transaction.provider.relationships.profileImage.data && entity.id.uuid === transaction.provider.relationships.profileImage.data.id.uuid && entity.type === "image";
        });
      }
    } // Provider Stripe account


    if (transaction.provider && transaction.provider.relationships && transaction.provider.relationships.stripeAccount) {
      transaction.provider.stripeAccount = sdkResponse.data.included.find(function (entity) {
        return transaction.provider.relationships.stripeAccount.data && entity.id.uuid === transaction.provider.relationships.stripeAccount.data.id.uuid;
      });
    } // Map customer and customer profile image


    if (transaction.relationships.customer && transaction.relationships.customer.data) {
      // Customer
      transaction.customer = sdkResponse.data.included.find(function (entity) {
        return entity.id.uuid === transaction.relationships.customer.data.id.uuid && entity.type === "user";
      }); // Customer profile image

      if (transaction.customer && transaction.customer.relationships && transaction.customer.relationships.profileImage) {
        transaction.customer.profileImage = sdkResponse.data.included.find(function (entity) {
          return transaction.customer.relationships.profileImage.data && entity.id.uuid === transaction.customer.relationships.profileImage.data.id.uuid && entity.type === "image";
        });
      }
    } // Map booking


    if (transaction.relationships.booking && transaction.relationships.booking.data && transaction.relationships.booking.data.id) {
      transaction.booking = sdkResponse.data.included.find(function (entity) {
        return entity.id.uuid === transaction.relationships.booking.data.id.uuid && entity.type === "booking";
      });
    }

    return transaction;
  });
  return sdkResponse;
};

var ensure = function ensure(transaction) {
  var booking = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var listing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var provider = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var empty = {
    id: null,
    type: "transaction",
    attributes: {
      lastTransition: null,
      lineItems: [],
      metadata: {},
      processName: null,
      processVersion: null,
      protectedData: {},
      transitions: []
    },
    booking: booking,
    listing: listing,
    provider: provider
  };
  return merge(empty, transaction);
};

var ensureProposal = function ensureProposal(transaction) {
  var booking = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var listing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var provider = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var empty = {
    attributes: {
      protectedData: {
        suggestedTimes: []
      }
    }
  };
  return merge(empty, ensure(transaction, booking, listing, provider));
};

var isOpen = function isOpen(transaction) {
  var ensuredTransaction = ensure(transaction);
  var _ensuredTransaction$a = ensuredTransaction.attributes,
      lastTransition = _ensuredTransaction$a.lastTransition,
      processName = _ensuredTransaction$a.processName;

  switch (processName) {
    case PROCESS_NAME_BOOKING:
      return ![TRANSITION_CANCEL, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_AFTER_DELIVERY, TRANSITION_CLOSE, TRANSITION_COMPLETE, TRANSITION_CUSTOMER_CANCEL, TRANSITION_DECLINE, TRANSITION_DELIVER, TRANSITION_EXPIRE, TRANSITION_PROVIDER_CANCEL].includes(lastTransition);

    case PROCESS_NAME_PAYMENT:
      return ![TRANSITION_CANCEL_AFTER_CAPTURE, TRANSITION_CANCEL_AFTER_COMPLETION, TRANSITION_CANCEL_BEFORE_CAPTURE, TRANSITION_CLOSE, TRANSITION_COMPLETE, TRANSITION_EXPIRE_PAYMENT].includes(lastTransition);

    default:
      return null;
  }
};
/**
 * Calculates the breakdown object based on the given transaction parameters
 *
 * @param unitPrice {number}
 * @param quantity {number}
 * @param deductFees {boolean}
 * @param availableCredit {number}
 * @param operatorPercentage {number}
 * @returns {{creditDeduction: number, operatorFee: number, paymentFee: number, total: number, donation: number, subTotal: number}}
 */


var calculateBreakdown = function calculateBreakdown(unitPrice, quantity, deductFees, availableCredit, operatorPercentage) {
  var breakdown = {
    donation: 0,
    operatorFee: 0,
    paymentFee: 0,
    creditDeduction: 0,
    subTotal: 0,
    total: 0
  };
  var _config$transaction = config.transaction,
      operatorFeeMultiplier = _config$transaction.operatorFeeMultiplier,
      paymentFeeMultiplier = _config$transaction.paymentFeeMultiplier,
      operatorFeeCap = _config$transaction.operatorFeeCap,
      paymentFeeAdditionalCharge = _config$transaction.paymentFeeAdditionalCharge; // Calculate initial donation (before fees)

  breakdown.donation = Math.round((typeof unitPrice === "object" ? unitPrice.amount : unitPrice) * quantity); // Calculate operator fee, this is a fixed 15% of the original donation value (without deducted fees, if any)
  // either 0.15 or operatorPercentage from user metadata eg. 5 / 100

  var variableOperatorDeduction = operatorPercentage >= 0 ? operatorPercentage / 100 : operatorFeeMultiplier;
  breakdown.operatorFee = Math.min(Math.round(breakdown.donation * variableOperatorDeduction), operatorFeeCap); // Remove the operator fee from the donation if fees are deducted. Also calculate the sub total for the next step.

  if (deductFees) {
    breakdown.subTotal = breakdown.donation;
    breakdown.donation -= breakdown.operatorFee;
  } else {
    // donation doesn't change if you don't deduct fees from it...
    breakdown.subTotal = breakdown.donation + breakdown.operatorFee;
  } // Apply payment fees (with or without deduction of fees)


  if (deductFees && breakdown.subTotal > 0) {
    breakdown.paymentFee = Math.round(breakdown.subTotal * (1 - 1 / paymentFeeMultiplier)) + paymentFeeAdditionalCharge;
    breakdown.donation -= breakdown.paymentFee;
  } else if (breakdown.subTotal > 0) {
    breakdown.paymentFee = Math.round((breakdown.subTotal + paymentFeeAdditionalCharge) * (paymentFeeMultiplier - 1)) + paymentFeeAdditionalCharge;
    breakdown.subTotal += breakdown.paymentFee;
  } // Apply credit


  if (availableCredit >= breakdown.subTotal) {
    // There is more (or exactly enough) credit available then is needed, only add the credit deduction
    breakdown.creditDeduction = breakdown.subTotal;
  } else if (availableCredit > 0 && availableCredit < breakdown.subTotal) {
    // There is credit available, but not enough for the whole transaction.
    breakdown.creditDeduction = Math.round(availableCredit);
  }

  breakdown.subTotal -= breakdown.creditDeduction; // Apply payment fees (with or without deduction of fees)
  // if (deductFees && breakdown.subTotal > 0) {
  //   breakdown.paymentFee = Math.round(
  //     ((breakdown.subTotal - paymentFeeAdditionalCharge) /
  //       (1 + paymentFeeMultiplier)) *
  //       paymentFeeMultiplier +
  //       paymentFeeAdditionalCharge
  //   );
  //   breakdown.donation -= breakdown.paymentFee;
  // } else if (breakdown.subTotal > 0) {
  //   breakdown.paymentFee = Math.round(
  //     breakdown.subTotal * (1 + paymentFeeMultiplier) +
  //       paymentFeeAdditionalCharge -
  //       breakdown.subTotal
  //   );
  //   breakdown.subTotal += breakdown.paymentFee;
  // }
  // Calculate total (due) amount

  breakdown.total = Math.round(breakdown.subTotal);
  breakdown.subTotal += breakdown.creditDeduction;
  return breakdown;
};

var convertToMoneyBreakdown = function convertToMoneyBreakdown(breakdown) {
  var donation = breakdown.donation,
      operatorFee = breakdown.operatorFee,
      paymentFee = breakdown.paymentFee,
      creditDeduction = breakdown.creditDeduction,
      subTotal = breakdown.subTotal,
      total = breakdown.total;
  var currency = config.transaction.currency;
  return {
    donation: new Money(Math.round(donation), currency),
    operatorFee: new Money(Math.round(operatorFee), currency),
    paymentFee: new Money(Math.round(paymentFee), currency),
    creditDeduction: new Money(Math.round(creditDeduction), currency),
    subTotal: new Money(Math.round(subTotal), currency),
    total: new Money(Math.round(total), currency)
  };
};

var convertBreakdownToLineItems = function convertBreakdownToLineItems(breakdown) {
  var donation = breakdown.donation,
      operatorFee = breakdown.operatorFee,
      paymentFee = breakdown.paymentFee,
      creditDeduction = breakdown.creditDeduction; // Base line items, these are always visible

  var lineItems = [{
    code: LINE_ITEM_DONATION,
    includeFor: ["customer", "provider"],
    unitPrice: donation,
    quantity: new BigDecimal("1"),
    lineTotal: donation,
    reversal: false
  }, {
    code: LINE_ITEM_OPERATOR_FEE,
    includeFor: ["customer"],
    unitPrice: operatorFee,
    quantity: new BigDecimal("1"),
    lineTotal: operatorFee,
    reversal: false
  }]; // Add payment fee if any

  if (paymentFee.amount) {
    lineItems.push({
      code: LINE_ITEM_PAYMENT_FEE,
      includeFor: ["customer"],
      unitPrice: paymentFee,
      quantity: new BigDecimal("1"),
      lineTotal: paymentFee,
      reversal: false
    });
  } // Add credit deduction if any


  if (creditDeduction.amount) {
    var creditDeductionTotal = new Money(creditDeduction.amount * -1, config.transaction.currency);
    lineItems.push({
      code: LINE_ITEM_GIVSLY_CREDIT,
      includeFor: ["customer", "provider"],
      unitPrice: creditDeduction,
      quantity: new BigDecimal("-1"),
      lineTotal: creditDeductionTotal,
      reversal: true
    });
  }

  return lineItems;
};

var calculatePayoutTotal = function calculatePayoutTotal(breakdown) {
  var donation = breakdown.donation,
      total = breakdown.total;
  return new Money(total > donation ? donation : total, config.transaction.currency);
};

var requiresPayment = function requiresPayment(ensuredTransaction) {
  var breakdown = ensuredTransaction.attributes.metadata.breakdown;
  return breakdown && breakdown.total > 0;
};

var getAppliedCredit = function getAppliedCredit(ensuredTransaction) {
  var creditLineItem = ensuredTransaction.attributes.lineItems.find(function (l) {
    return l.code === LINE_ITEM_GIVSLY_CREDIT;
  });

  if (creditLineItem && creditLineItem.lineTotal && creditLineItem.lineTotal.amount < 0) {
    return creditLineItem.lineTotal.amount * -1;
  }

  return 0;
};
/**
 * Gets the inbox state which is a simplified form of the transaction state (some states are merged)
 *
 * @param lastTransition
 * @return string
 */


var getInboxState = function getInboxState(lastTransition) {
  if (constants.ACCEPTED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_ACCEPTED;
  } else if (constants.DECLINED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_DECLINED;
  } else if (constants.EXPIRED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_EXPIRED;
  } else if (constants.CANCELED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_CANCELED;
  } else if (constants.COMPLETED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_COMPLETED;
  } else if (constants.PROPOSED_TRANSITIONS.includes(lastTransition)) {
    return constants.STATE_PROPOSED;
  } else {
    return constants.STATE_PENDING;
  }
};

var isProposal = function isProposal(transaction) {
  return ensure(transaction).attributes.transitions.some(function (t) {
    return t.transition === constants.TRANSITION_PROPOSE;
  });
};

var isOutreach = function isOutreach(transaction) {
  return ensure(transaction).attributes.processName === constants.PROCESS_NAME_OUTREACH_PAYMENT;
};

var isDeclined = function isDeclined(transaction) {
  var lastTransition = transaction.attributes.lastTransition;
  return constants.DECLINED_TRANSITIONS.includes(lastTransition);
};

var isAccepted = function isAccepted(transaction) {
  var transition = transaction.attributes.transitions.find(function (t) {
    return constants.ACCEPTED_TRANSITIONS.includes(t.transition);
  });
  return typeof transition === "object";
};
/**
 * Checks that the given Sharetribe transaction is a booking transaction.
 *
 * @param transaction
 * @return {boolean}
 */


var isBookingTransaction = function isBookingTransaction(transaction) {
  // return transaction?.attributes?.processName === PROCESS_NAME_BOOKING; //this syntax doesn't work with sharetribe app
  return Boolean(transaction && transaction.attributes && transaction.attributes.processName === PROCESS_NAME_BOOKING);
};

module.exports = _objectSpread({
  calculateBreakdown: calculateBreakdown,
  calculatePayoutTotal: calculatePayoutTotal,
  convertBreakdownToLineItems: convertBreakdownToLineItems,
  convertToMoneyBreakdown: convertToMoneyBreakdown
}, constants, {
  ensure: ensure,
  ensureProposal: ensureProposal,
  getAppliedCredit: getAppliedCredit,
  getInboxState: getInboxState,
  isAccepted: isAccepted,
  isBookingTransaction: isBookingTransaction,
  isDeclined: isDeclined,
  isOpen: isOpen,
  isProposal: isProposal,
  isOutreach: isOutreach,
  mapRelationsFromSdkResponse: mapRelationsFromSdkResponse,
  requiresPayment: requiresPayment
});