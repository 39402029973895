import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  FieldSelect,
  FieldTextInput,
  IconSpinner,
  ImageFromFile,
} from '../../components';
import config from '../../config';

import css from './NonprofitInfoForm.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const MAX_DESCRIPTION_LENGTH = 1000;

class NonprofitInfoForm extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
    };
    this.submittedValues = {};
    this.getTranslation = this.getTranslation.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);

    this.state = {
      descriptionLength: this.props.initialValues.bio ? this.props.initialValues.bio.length : 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  getTranslation = (name, variables = {}) => {
    return this.props.intl.formatMessage({ id: `NonprofitInfoForm.${name}` }, variables);
  };

  /**
   * It's not possible to use the onChange event on the input directly, as this will interfere with
   * the validation process.
   *
   * @param target
   */
  handleFormChange = ({ target }) => {
    if (target.name === 'bio') {
      this.setState({
        descriptionLength: target.value.length,
      });

      // Trigger the validation error directly
      if (target.value.length > MAX_DESCRIPTION_LENGTH) {
        target.blur();
        target.focus();
      }
    }
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={(fieldRenderProps) => {
          const {
            className,
            currentUser,
            handleSubmit,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            uploadProfileImageError,
            uploadProfileImageInProgress,
            updateProfileError,
            updateProfileInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          const { onboardingCompleted } = user.attributes.profile.privateData;

          const uploadingOverlay =
            uploadProfileImageInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadProfileImageError && !uploadProfileImageInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadProfileImageInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>{this.getTranslation('change')}</div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  {this.getTranslation('addNonprofitLogo')}
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  {this.getTranslation('addNonprofitLogo')}
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>{this.getTranslation('updateProfileFailed')}</div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateProfileInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            uploadProfileImageInProgress ||
            submitInProgress;

          const enterUrl = this.getTranslation('enterUrl');
          const optionalSuffix = this.getTranslation('optional');



          return (
            <Form
              className={classes}
              onChange={this.handleFormChange}
              onSubmit={(e) => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>{this.getTranslation('nonprofitLogo')}</h3>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadProfileImageError}
                  disabled={uploadProfileImageInProgress}
                >
                  {(fieldProps) => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = (e) => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          {this.getTranslation('imageUploadFailedFileTooLarge')}
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>{this.getTranslation('imageUploadFailed')}</div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                <div className={css.tip}>{this.getTranslation('tip')}</div>
                <div className={css.fileInfo}>{this.getTranslation('fileInfo')}</div>
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>Nonprofit Essentials</h3>
                <FieldTextInput
                  className={css.field}
                  id="name"
                  name="name"
                  label={this.getTranslation('name')}
                  placeholder={this.getTranslation('name.placeholder')}
                  type="text"
                  validate={validators.requiredAndNonEmptyString(
                    this.getTranslation('name.required')
                  )}
                />

                <FieldSelect
                  className={css.field}
                  defaultOptionLabel={this.getTranslation('nonprofitType.placeholder')}
                  defaultOptionValue=""
                  id="nonprofitType"
                  name="nonprofitType"
                  label={this.getTranslation('nonprofitType')}
                  showDefaultOption={true}
                  validate={validators.requiredAndNonEmptyString(
                    this.getTranslation('nonprofitType.required')
                  )}
                >
                  <option value="501c3">{this.getTranslation('nonprofitType.501c3')}</option>
                </FieldSelect>

                <FieldTextInput
                  required
                  className={classNames(css.field, css.einNumber)}
                  id="einNumber"
                  name="einNumber"
                  label={this.getTranslation('einNumber')}
                  placeholder={this.getTranslation('einNumber.placeholder')}
                  type="text"
                  validate={validators.validEIN(
                    'Invalid EIN format. Please use XX-XXXXXXX.',
                    true
                  )}
                />

                <FieldSelect
                  className={classNames(css.field, css.category)}
                  defaultOptionLabel={this.getTranslation('category.placeholder')}
                  defaultOptionValue=""
                  id="category"
                  label={this.getTranslation('category')}
                  name="category"
                  showDefaultOption={true}
                  validate={validators.requiredAndNonEmptyString(
                    this.getTranslation('category.required')
                  )}
                >
                  {config.custom.categories.map((category) => {
                    return (
                      <option key={category.key} value={category.key}>
                        {category.label}
                      </option>
                    );
                  })}
                </FieldSelect>

                <FieldTextInput
                  className={css.field}
                  id="privateEmail"
                  name="privateEmail"
                  label={this.getTranslation('privateEmail')}
                  placeholder={this.getTranslation('privateEmail.placeholder')}
                  type="text"
                  validate={validators.composeValidators(
                    validators.requiredAndNonEmptyString(
                      this.getTranslation('privateEmail.required')
                    ),
                    validators.emailFormatValid(
                      this.getTranslation('privateEmail.emailFormatValid'),
                      false
                    )
                  )}
                />
                <FieldTextInput
                  className={css.field}
                  id="website"
                  name="website"
                  label={this.getTranslation('website')}
                  placeholder={this.getTranslation('website.placeholder')}
                  type="text"
                  validate={validators.composeValidators(
                    validators.validBusinessURL(
                      this.getTranslation('website.validBusinessURL'),
                      true
                    )
                  )}
                />
              </div>

              <div className={css.sectionContainer}>
              <h3 className={css.sectionTitle}>Optional</h3>

                <FieldTextInput
                  className={classNames(css.field, css.description)}
                  id="bio"
                  name="bio"
                  label={this.getTranslation('description')}
                  placeholder={this.getTranslation('description.placeholder')}
                  type="textarea"
                  validate={validators.composeValidators(
                    validators.maxLength(
                      this.getTranslation('description.exceedsMaximumLength'),
                      1000
                    )
                  )}
                />
                {this.state.descriptionLength <= MAX_DESCRIPTION_LENGTH ? (
                  <span className={css.descriptionLength}>
                    {this.getTranslation('description.charactersRemaining', {
                      characters: 'characters',
                      number: MAX_DESCRIPTION_LENGTH - this.state.descriptionLength,
                    })}
                  </span>
                ) : null}

                <FieldSelect
                  className={css.field}
                  defaultOptionLabel={this.getTranslation('location.placeholder')}
                  defaultOptionValue=""
                  id="location"
                  name="location"
                  label={this.getTranslation('location')}
                  showDefaultOption={true}
                  // validate={validators.requiredAndNonEmptyString(
                  //   this.getTranslation('location.required')
                  // )}
                >
                  {config.custom.nonprofitLocations.map((location) => {
                    return (
                      <option key={location.key} value={location.key}>
                        {location.label}
                      </option>
                    );
                  })}
                </FieldSelect>


              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>{this.getTranslation('contactInfo')}</h3>
                <FieldTextInput
                  className={css.field}
                  id="phone"
                  name="phone"
                  label={this.getTranslation('phone')}
                  labelSuffix={this.getTranslation('optional')}
                  placeholder={this.getTranslation('phone.placeholder')}
                  type="text"
                />
                <FieldTextInput
                  className={css.field}
                  id="publicEmail"
                  name="publicEmail"
                  label={this.getTranslation('publicEmail')}
                  labelSuffix={this.getTranslation('optional')}
                  placeholder={this.getTranslation('publicEmail.placeholder')}
                  type="text"
                  validate={validators.emailFormatValid(
                    this.getTranslation('publicEmail.emailFormatValid'),
                    false
                  )}
                />

              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>{this.getTranslation('address')}</h3>
                <FieldTextInput
                  className={css.field}
                  id="street"
                  name="street"
                  label={this.getTranslation('street')}
                  labelSuffix={optionalSuffix}
                  placeholder={this.getTranslation('street.placeholder')}
                  type="text"
                />
                <div className={css.twoFields}>
                  <FieldTextInput
                    className={classNames(css.field, css.firstField)}
                    id="postalCode"
                    name="postalCode"
                    label={this.getTranslation('postalCode')}
                    labelSuffix={optionalSuffix}
                    placeholder={this.getTranslation('postalCode.placeholder')}
                    type="text"
                  />
                  <FieldTextInput
                    className={classNames(css.field, css.lastField)}
                    id="city"
                    name="city"
                    label={this.getTranslation('city')}
                    labelSuffix={optionalSuffix}
                    placeholder={this.getTranslation('city.placeholder')}
                    type="text"
                  />
                </div>
                <FieldTextInput
                  className={css.field}
                  id="state"
                  name="state"
                  label={this.getTranslation('state')}
                  labelSuffix={optionalSuffix}
                  placeholder={this.getTranslation('state.placeholder')}
                  type="text"
                />
              </div>
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>{this.getTranslation('socialMedia')}</h3>
                <FieldTextInput
                  className={css.field}
                  id="facebookUrl"
                  name="facebookUrl"
                  label={this.getTranslation('facebookUrl')}
                  labelSuffix={optionalSuffix}
                  placeholder={enterUrl}
                  type="text"
                  // validate={validators.validFacebookUrl(this.getTranslation('invalidFacebookUrl'))}
                />
                <FieldTextInput
                  className={css.field}
                  id="twitterUrl"
                  name="twitterUrl"
                  label={this.getTranslation('twitterUrl')}
                  labelSuffix={optionalSuffix}
                  placeholder={enterUrl}
                  type="text"
                  // validate={validators.validTwitterUrlOrHandle(this.getTranslation('invalidTwitterUrlOrHandle'))}
                />
                <FieldTextInput
                  className={css.field}
                  id="instagramUrl"
                  name="instagramUrl"
                  label={this.getTranslation('instagramUrl')}
                  labelSuffix={optionalSuffix}
                  placeholder={enterUrl}
                  type="text"
                  // validate={validators.validInstagramUrlOrHandle(this.getTranslation('invalidInstagramUrlOrHandle'))}
                />
                <FieldTextInput
                  className={css.field}
                  id="linkedInUrl"
                  name="linkedInUrl"
                  label={this.getTranslation('linkedInUrl')}
                  labelSuffix={optionalSuffix}
                  placeholder={enterUrl}
                  type="text"
                  // validate={validators.validLinkedInURL(this.getTranslation('invalidLinkedInUrl', false))}
                />
                <FieldTextInput
                  className={css.field}
                  id="youtubeUrl"
                  name="youtubeUrl"
                  label={this.getTranslation('youtubeUrl')}
                  labelSuffix={optionalSuffix}
                  placeholder={enterUrl}
                  type="text"
                  // validate={validators.validUrl(this.getTranslation('invalidYouTubeUrl'))}
                />
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                {this.getTranslation(onboardingCompleted ? 'saveChanges' : 'next')}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

NonprofitInfoForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  isPublished: bool.isRequired,
  profileImage: object,
  onImageUpload: func.isRequired,
  uploadProfileImageError: propTypes.error,
  uploadProfileImageInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileInProgress: bool.isRequired,
  updateProfileReady: bool,
};

NonprofitInfoForm.defaultProps = {
  className: null,
  isPublished: false,
  uploadImageError: null,
  uploadInProgress: false,
  updateProfileError: null,
  updateProfileReady: false,
};

NonprofitInfoForm.displayName = 'NonprofitInfoForm';

export default NonprofitInfoForm;
