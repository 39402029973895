import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import { intlShape } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import { Form, NamedLink, PrimaryButton } from '../../components';
import css from './NonprofitTermsConditionsAndPrivacyForm.css';
import { propTypes } from '../../util/types';
import FieldCheckboxComponent from '../../components/FieldCheckbox/FieldCheckbox';
import { func } from 'prop-types';

class NonprofitTermsConditionsAndPrivacyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
    this.getTranslation = this.getTranslation.bind(this);
  }

  getTranslation(key) {
    return this.props.intl.formatMessage({
      id: `NonprofitTermsConditionsAndPrivacyForm.${key}`,
    });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <FinalForm
        {...this.props}
        onSubmit={() => {}}
        render={(fieldRenderProps) => {
          const {
            rootClassName,
            className,
            inProgress,
            invalid,
            pristine,
            ready,
            values,
          } = fieldRenderProps;

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || pristine || pristineSinceLastSubmit || inProgress;
          const agreeToService = this.getTranslation('agreeToService');

          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedValues = values;
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              <h2 className={css.header}>{this.getTranslation('header')}</h2>
              <p className={css.description}>{this.getTranslation('description')}</p>
              <p className={css.description}>
                {this.getTranslation('descriptionAuthorizedPerson')}
              </p>
              <div className={css.checkboxes}>
                <FieldCheckboxComponent
                  className={css.checkbox}
                  value="Yes"
                  name="agreeTermsOfService"
                  id="agreeTermsOfService"
                  //validate={validators.required(this.getTranslation('termsOfService.required'))}
                  label={
                    <>
                      {agreeToService}{' '}
                      <NamedLink name="TermsOfServiceNPOPage" target="_blank" rel="noopener noreferrer">
                        {this.getTranslation('termsOfService')}
                      </NamedLink>
                    </>
                  }
                />
                <FieldCheckboxComponent
                  className={css.checkbox}
                  value="Yes"
                  name="agreePrivacyPolicy"
                  id="agreePrivacyPolicy"
                  //validate={validators.required(this.getTranslation('privacyPolicy.required'))}
                  label={
                    <>
                      {agreeToService}{' '}
                      <NamedLink name="PrivacyPolicyPage" target="_blank" rel="noopener noreferrer">
                        {this.getTranslation('privacyPolicy')}
                      </NamedLink>
                    </>
                  }
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                >
                  {this.getTranslation('save')}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

NonprofitTermsConditionsAndPrivacyForm.propTypes = {
  currentUser: propTypes.currentUser.isRequired,
  handleSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

export default NonprofitTermsConditionsAndPrivacyForm;
